<template>
  <div class="tests-background" :class="{ loader: loading }">
    <!--    <h1 v-if="onlyTests.length" class="page-title">{{ $t("Tests.testsHeading") }}</h1>-->
    <loader v-if="loading" center></loader>
    <div v-else-if="isArchiveInactiveTests" class="tabs-top__wrapper">
      <div class="tabs-top">
        <span
          class="tabs-top__title"
          :class="{ 'tabs-top__title--active': tab === 'not_started' }"
          @click="clickTab('not_started')"
        >
          {{ $t("Tests.not_started") }}
        </span>
        <span
          class="tabs-top__title"
          :class="{ 'tabs-top__title--active': tab === 'finish' }"
          @click="clickTab('finish')"
        >
          {{ $t("Tests.finished") }}
        </span>
      </div>
    </div>
    <div v-if="!loading && tab === 'not_started'" class="tests">
      <span v-if="findTestWithTimer" class="test-notification">{{
        $t("Tests.testTimerNotification")
      }}</span>
      <p v-if="isKingOfMountainAvailable && formattedTests" class="tests-heading">
        {{ $t("Tests.testGroup") }}
      </p>
      <div v-if="formattedTests.length" class="groups">
        <div v-for="(group, index) in formattedTests" :key="index" class="group">
          <div v-if="displayGroup(group)">
            <h4 v-if="group.group_name" class="group-header">
              {{ group.group_name || $t("Tests.notFilled") }}
            </h4>
            <div class="tests" :class="{ 'test-sep': hasOneGroup() }">
              <test-item
                v-for="test in group.not_started"
                :key="test.id"
                status-name="not_started"
                :test="test"
              />
              <test-item
                v-for="test in group.started"
                :key="test.id"
                status-name="started"
                :test="test"
              />
              <template v-if="!isArchiveInactiveTests">
                <test-item
                  v-for="test in group.finished"
                  :key="test.id"
                  status-name="finished"
                  :test="test"
                />
              </template>
              <template v-else>
                <test-item
                  v-for="test in infinitTests"
                  :key="test.id"
                  status-name="finished"
                  :test="test"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <p v-if="isKingOfMountainAvailable" class="tests-heading">{{ $t("Tests.gameGroup") }}</p>
      <game-item
        v-if="isKingOfMountainAvailable"
        :game="{ title: $t('testNGames.kingOfTheMountain'), to: 'mountain' }"
      />
    </div>

    <div v-if="!loading && tab === 'finish' && isArchiveInactiveTests" class="test-group">
      <div v-for="(group, index) in formattedTests" :key="index" class="group">
        <div v-if="displayGroup(group, 'archive')">
          <h4 v-if="group.group_name" class="group-header">
            {{ group.group_name || $t("Tests.notFilled") }}
          </h4>
          <div class="archive-tests" :class="{ 'test-sep': hasOneGroup('archive') }">
            <test-item
              v-for="test in group.finished"
              :key="test.id"
              :test="test"
              status-name="finished"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TestItem from "@/components/event/room/Tests/components/TestItem";
import GameItem from "@/components/event/room/Tests/components/GameItem";
import Loader from "@/components/common/elements/Loader";
import { games, polls, room } from "@/store/modules/store.namespaces";
import { FORMATTED_TESTS, ONLY_TESTS } from "@/store/modules/polls/getter-types";
import { FINISH_TEST, GET_TESTS } from "@/store/modules/polls/action-types";
import { GET_GAMES } from "@/store/modules/games/action-types";

const TESTS_RENEW_INTERVAL = 5 * 60 * 1000;

export default {
  components: {
    TestItem,
    GameItem,
    Loader,
  },
  data() {
    return {
      loading: true,
      tab: this.$route.query.showFinished ? "finish" : "not_started",
      testsListsRenewIntvl: null,
      testRenewIsActiveFlag: false,
      findExpiredStartedTests: [],
    };
  },
  computed: {
    ...mapState(room, {
      isArchiveInactiveTests: state => state.roomInfo.archive_inactive_tests,
      buisnessUnits: state => state.roomInfo.buisnessUnits || [],
    }),
    ...mapState(games, {
      isKingOfMountainAvailable: state => !!state.availableGames.king,
    }),
    ...mapGetters(polls, {
      formattedTests: FORMATTED_TESTS,
      onlyTests: ONLY_TESTS,
    }),
    ...mapState(room, ["roomId"]),
    /**
     * Метод фильтрует и получает бесконечные тесты.
     *
     * @returns {Array} - Возвращает массив тестов, которые будут в Архиве.
     */
    infinitTests() {
      const arrInfinitTests = [];
      for (const group of this.formattedTests) {
        const infinitTest = group?.finished.filter(t => t.endless_passin);
        infinitTest?.length && arrInfinitTests.push(...infinitTest);
      }
      return arrInfinitTests;
    },
    /**
     * Метод фильтрует и получает законченные, доступные на просмотр пользователю тесты.
     *
     * @returns {Array} - Возвращает массив тестов, которые будут в Архиве.
     */
    finishedTests() {
      const finishedTests = [];
      for (const group of this.formattedTests) {
        const finishedTest = group?.finished.filter(t => !t.endless_passin);
        finishedTest?.length && finishedTests.push(...finishedTest);
      }
      return finishedTests;
    },
    /**
     * Метод поиска теста с активным таймером.
     *
     * @returns {boolean} - Есть ли в списке тестов тест с активным таймером (проходимый тест).
     */
    findTestWithTimer() {
      return this.onlyTests?.some(item => +item?.timer);
    },
  },
  watch: {
    /**
     * Отслеживание изменения id комнаты
     *
     * @param {number} newVal - id текущей комнаты
     * @param {number} oldVal - id старой комнаты
     */
    roomId(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.getAvailableGames();
      }
    },
    /**
     * Отслежка запущенных тестов и проверка на то, просроченные они или нет.
     */
    formattedTests() {
      this.findExpiredStartedTests = [];
      for (const group of this.formattedTests) {
        const groups = group?.started.filter(
          item =>
            Number(item?.timer) &&
            !item?.has_repeated &&
            item.timer - (Math.round(Date.now() / 1000) - item.time_started) <= 0,
        );
        this.findExpiredStartedTests.push(...groups);
      }
    },
    /**
     * Если найдены просроченные тесты - запускается механизм который их закрывает
     */
    findExpiredStartedTests() {
      this.updateExpiredTests();
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.testRenewIsActiveFlag = false;
    if (this.testsListsRenewIntvl) {
      clearInterval(this.testsListsRenewIntvl);
    }
  },
  methods: {
    ...mapActions(polls, {
      getAllTests: GET_TESTS,
      finishTest: FINISH_TEST,
    }),
    ...mapActions(["setTestResultComplited"]),
    ...mapActions(games, {
      getAvailableGames: GET_GAMES,
    }),
    clickTab(status) {
      this.tab = status;
    },
    disableLoader() {
      this.loading = false;
    },
    /**
     * Закрытие истекших тестов.
     */
    async updateExpiredTests() {
      if (this.findExpiredStartedTests?.length) {
        await Promise.all(
          this.findExpiredStartedTests.map(item => this.finishTest({ testId: item?.id })),
        );
        await this.getAllTests();
      }
    },
    async init() {
      try {
        await this.getAllTests(this.$route.params.id);
        await this.updateExpiredTests();
        this.disableLoader();
      } catch (e) {
        this.disableLoader();
      }
      if (this.roomId) {
        this.getAvailableGames();
      }
      this.testsListsRenewIntvl = setInterval(() => {
        if (!this.testRenewIsActiveFlag) {
          this.testRenewIsActiveFlag = true;
          this.getAllTests(this.$route.params.id).then(() => {
            this.getAvailableGames();
            this.testRenewIsActiveFlag = false;
          });
        }
      }, TESTS_RENEW_INTERVAL);
    },
    /**
     * Метод определения показа групп.
     *
     * @param {object} group - объект группы с тестами и их статусами.
     * @param type
     * @returns {number} - длина массива с группами.
     */
    displayGroup(group, type = "tests") {
      if (type == "tests")
        return (
          (!this.isArchiveInactiveTests && group?.finished?.length) ||
          group?.not_started?.length ||
          group?.started?.length
        );
      if (this.isArchiveInactiveTests) return group?.finished?.length;
    },
    // Есть ли хотя бы одна группа с тестом.
    hasOneGroup(type = "tests") {
      if (type == "tests")
        return this.formattedTests.some(
          group =>
            group.group_name &&
            (group.not_started.length ||
              group.started.length ||
              (!this.isArchiveInactiveTests && group.finished.length)),
        );
      if (this.isArchiveInactiveTests)
        return this.formattedTests.some(group => group.group_name && group.finished.length);
    },
  },
};
</script>

<style scoped lang="less">
.tabs-top {
  display: flex;
  margin: 0 auto;
  cursor: pointer;
  font-size: 16px;
  overflow: hidden;
  max-width: 980px;
  justify-content: center;
  color: var(--base-color);
  width: 100%;

  &__wrapper {
    margin: 16px 8px 0;
    text-align: center;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    font-size: 16px;
    min-width: 112px;
    border: solid 1px var(--base-color);
    height: 27px;
    background: white;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &:not(:last-child) {
      border-right: 1px solid var(--base-color);
    }

    &--active {
      background: var(--base-color);
      color: white;
    }
  }
}

.loader {
  align-items: center;
}

.tests-background {
  flex: auto;
  width: 100%;
  min-height: 300px;
  background: url("~@/assets/images/pattern.png");
  overflow: auto;
}

.tests {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 8px 0;
}

.tests-heading {
  font-size: 22px;
  flex-basis: 100%;
  margin: 0 0 10px 0;
  padding: 0;
  font-weight: 600;
}

.test-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.show-balls {
  font-weight: bold;
  width: 100%;
  margin: 10px 0;
}

.group-header {
  text-align: left;
  font-weight: 700;
  padding: 8px;
}

.groups {
  width: 100%;

  .group {
    &:first-child {
      .test-sep {
        border-top: none;
      }
    }
    .test-sep {
      border-top: solid 1.5px var(--base-color);
    }
  }
}

.group {
  &:first-child {
    .test-sep {
      border-top: none;
    }
  }
  .test-sep {
    border-top: solid 1.5px var(--base-color);
  }
}

.page-title {
  font-size: 24px;
  text-align: center;
  margin: 10px 0;
}

.test-notification {
  padding: 7.5px;
  background-color: #f1d7d8;
  border-radius: 4px;
  color: #691d27;
  border: 1px solid #e9c2c9;
  max-width: max-content;
  margin: 8px;
}
.test-group {
  max-width: 980px;
  margin: 0 auto;
}

.archive-tests {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
