<template>
  <div class="game" @click="handleRouteChange">
    <span class="icon-arrow-right game__arrow-icon"></span>

    <div ref="titleBox" class="game-title">
      <p ref="titlePara" v-line-clamp:20="3" class="game-title__para">
        {{ game.title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["game"],
  mounted() {
    // add ellipsis if title is more then three lines;
    const wordArray = this.$refs.titlePara.innerHTML.split("");

    while (
      this.$refs.titlePara.clientHeight > this.$refs.titleBox.clientHeight
    ) {
      wordArray.pop();
      this.$refs.titlePara.innerHTML = wordArray.join("") + "...";
    }
  },
  methods: {
    handleRouteChange() {
      this.$router.push({ name: this.game.to });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_vars");

.game {
  position: relative;
  flex: auto;
  height: 89px;
  padding: 6px 45px 9px 12px;
  margin-bottom: 12px;
  overflow: hidden;
  font-size: 15px;
  color: var(--base-text-color);
  cursor: pointer;
  background: white;
  border: solid 1px var(--base-bg-color);
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    border-color: var(--base-color);
    box-shadow: 0 6px 8px 0 var(--base-shadow-color);
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    right: 17px;
    font-size: 21px;
    color: var(--base-color-lighter);
    transform: translateY(-50%);
  }
}

@media (max-width: 1023px) {
  .game {
    flex-basis: 100%;
    margin-right: 0;
  }
}
</style>
