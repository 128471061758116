<template>
  <div class="timer">
    <span v-if="timerNote" class="timer__note">
      {{ timerNote }}
    </span>
    <span class="timer__time">
      {{ timeString }}
    </span>
  </div>
</template>

<script>
import EasyTimer from "easytimer";
import { i18nPlurals } from "@/utils/index";

const ALLOWABLE_DIFFERENCE = 2;

export default {
  props: ["initialTimeInSeconds", "timerNote"],
  data() {
    return {
      timer: null,
      timeString: "...",
      remainingTimeInSeconds: this.initialTimeInSeconds || 0,
    };
  },
  computed: {
    stopInThisTime() {
      return Date.now() + (this.initialTimeInSeconds || 0) * 1000;
    },
    timerSettings() {
      return {
        countdown: true,
        precision: "seconds",
        startValues: { seconds: +this.remainingTimeInSeconds },
      };
    },
  },
  created() {
    if (!this.remainingTimeInSeconds) {
      return;
    }
    this.startTimer();
  },
  beforeDestroy() {
    this.timer.stop();
    this.timer = null;
  },
  methods: {
    startTimer() {
      this.timer = new EasyTimer();
      this.timer.start(this.timerSettings);
      this.timer.addEventListener("secondsUpdated", this.onSecondsUpdated);
      this.timer.addEventListener("targetAchieved", this.onTimeRunOut);
    },
    restartTimer() {
      this.timer.stop();
      this.timer.start(this.timerSettings);
    },
    onSecondsUpdated() {
      this.remainingTimeInSeconds = this.timer.getTotalTimeValues().seconds;
      let { minutes, seconds } = this.timer.getTimeValues();
      this.timeString = `
          ${this.getTimeTextValue(minutes, "minutes")}
          ${this.getTimeTextValue(seconds, "seconds")}`;
      this.checkNeedToRestart();
    },
    getTimeTextValue(value, type) {
      return `${
        +value > 0 ? value + " " + i18nPlurals(type + "V", value, "rus") : ""
      }`;
    },
    checkNeedToRestart() {
      const remainingFactTimeInSeconds = Math.round(
        (this.stopInThisTime - Date.now()) / 1000,
      );
      const difference =
        this.remainingTimeInSeconds - remainingFactTimeInSeconds;
      const needToRestart = Math.abs(difference) >= ALLOWABLE_DIFFERENCE;

      if (!needToRestart) {
        return;
      }
      if (remainingFactTimeInSeconds < 0) {
        this.onTimeRunOut();
      }
      this.remainingTimeInSeconds = remainingFactTimeInSeconds;
      this.restartTimer();
    },
    onTimeRunOut() {
      this.timer.stop();
      this.remainingTimeInSeconds = 0;
      this.$emit("time-run-out");
    },
  },
};
</script>
