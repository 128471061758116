<template>
  <div
    class="test"
    :class="{
      'test--disabled': testRepeatIsDisabled,
      'test--shake': testShakeAnimated,
    }"
    @click="onTestClick(test.id)"
  >
    <span class="icon-arrow-right test__arrow-icon"></span>

    <div class="test-meta">
      <span v-if="test.timer" class="test-meta__span timer" :title="$t('Tests.testWithTimer')">
        <i class="far fa-clock" />
      </span>

      <span
        v-if="test.status !== 'not_started'"
        :class="{ 'test-meta__span--pass': isPassed }"
        class="test-meta__span"
      >
        <span
          :class="{ 'test-meta__status-circle--pass': isPassed }"
          class="test-meta__status-circle"
        ></span>
        {{ status }}
      </span>
      <span class="test-meta__span">
        <span class="icon-people test-meta__test-taken"></span>
        {{ test.took }}
      </span>
    </div>

    <div ref="titleBox" class="test-title">
      <p ref="titlePara" class="test-title__para">{{ test.title }}</p>
      <test-timer
        v-if="testRepeatIsDisabled"
        class="test__timer"
        :initial-time-in-seconds="testRepeatAfterTime"
        :timer-note="$t('Tests.timerRepeatNote')"
        @time-run-out="onTimeRunOut"
      />
      <transition name="throbber-fade">
        <div v-if="throbberIsActive" class="load-throbber">
          <div class="throbber"></div>
        </div>
      </transition>
    </div>
    <loader v-show="loading" center />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import TestTimer from "@/components/event/room/Tests/components/TestTimer";
import {
  GET_COMMON_TEST_RESULTS,
  GET_TEST_RESULTS,
  GET_TESTS,
  RUN_TEST_POLL,
  SET_TEST_TIMER,
  SHOW_POLL_TEST_RESULT,
} from "@/store/modules/polls/action-types";
import { polls } from "@/store/modules/store.namespaces";
import { test } from "@/constants/polls/polls-module-types";
import { SET_SHOW_RESULT_AS_COMMON } from "@/store/modules/polls/mutation-types";
import Loader from "@/components/common/elements/Loader";

export default {
  components: {
    TestTimer,
    Loader,
  },
  props: ["test", "statusName"],
  data() {
    return {
      throbberIsActive: null,
      testShakeAnimated: false,
      testRepeatIsDisabled: !!this.test.repeat_pass_locked,
      statuses: {
        started: this.$t("testNGames.active"),
        finished: this.$t("testNGames.passed"),
      },
      loading: false,
    };
  },
  computed: {
    // Окончен ли тест
    isPassed() {
      return this.test.status === "finished";
    },
    // Статус теста
    status() {
      return this.statuses[this.test.status] || "";
    },
    // Есть ли время между прохождением теста
    testRepeatAfterTime() {
      return this.test.repeat_pass_wait;
    },
  },
  methods: {
    ...mapActions(polls, {
      // setTestTimer: SET_TEST_TIMER,
      runTestPoll: RUN_TEST_POLL,
      getResults: GET_TEST_RESULTS,
      showTestResult: SHOW_POLL_TEST_RESULT,
      getCommonResults: GET_COMMON_TEST_RESULTS,
      getAllTests: GET_TESTS,
    }),
    ...mapMutations(polls, {
      setShowResultAsCommon: SET_SHOW_RESULT_AS_COMMON,
    }),

    /**
     * Метод запуска теста.
     *
     * @param {number} id - id запускаемого теста
     */
    runTest(id) {
      let data;
      if (this.test.timer !== 0) {
        const timeStarted = this.getTimeStarted();
        data = {
          id,
          title: this.test.title,
          timer: this.test.timer,
          time_started: timeStarted,
          commonResult: this.test.commonResult,
          endless: this.test.endless_passing,
        };
      } else {
        data = id;
      }
      this.runTestPoll({
        type: test,
        payload: data,
      }).then(
        () => {
          this.throbberIsActive = null;
        },
        () => {
          this.throbberIsActive = null;
        },
      );
    },
    getTimeStarted() {
      if (this.test.time_started && this.test.endless_passing) {
        return Math.round(Date.now() / 1000);
      }
      return this.test.time_started ? this.test.time_started : Math.round(Date.now() / 1000);
    },
    /**
     * Метод, срабатываемый при нажатии на тест
     *
     * @param {number} id - id запускаемого теста
     */
    onTestClick(id) {
      if (!this.loading) {
        this.loading = true;
        if (this.testRepeatIsDisabled) {
          this.testShakeAnimated = true;
          setTimeout(() => (this.testShakeAnimated = false), 300);
          this.loading = false;
          return;
        }
        if (
          this.test.time_started &&
          +this.test.timer &&
          !this.test.endless_passing &&
          this.statusName !== "finished" &&
          this.checkTestTime() &&
          !this.test.has_repeated
        ) {
          this.handlerExpiredTest();
          this.loading = false;
          return;
        }
        this.throbberIsActive = true;
        if (this.statusName === "finished" && !this.test.endless_passing) {
          this.getTestResults(id);
        } else {
          if (this.test.commonResult) {
            this.setShowResultAsCommon({
              type: test,
              payload: true,
            });
          } else {
            this.setShowResultAsCommon({
              type: test,
              payload: false,
            });
          }
          this.runTest(id);
        }
        this.loading = false;
      } else {
        this.$notify({
          group: "roomSystem",
          type: "error",
          duration: 5000,
          title: this.$t("messages.common.please_wait"),
          text: this.$t("Tests.testIsLoading"),
        });
      }
    },
    async handlerExpiredTest() {
      // await this.setTestTimer({ id: this.test.id, finish: true });
      setTimeout(() => {
        this.getTestResults(this.test.id);
      }, 500);
    },
    checkTestTime() {
      return (
        +this.test.timer &&
        this.test.timer - (Math.round(Date.now() / 1000) - this.test.time_started) < 0
      );
    },
    getTestResults(id) {
      if (!this.test.commonResult) {
        this.getResults({
          type: test,
          payload: id,
        }).then(
          () => {
            this.throbberIsActive = null;
            this.showTestResult({
              type: test,
              testId: id,
            });
          },
          () => {
            this.throbberIsActive = null;
          },
        );
      } else {
        this.getCommonResults({
          type: test,
          payload: id,
        }).then(
          () => {
            this.throbberIsActive = null;
            this.showTestResult({
              type: test,
              testId: id,
              isCommon: true,
            });
          },
          () => {
            this.throbberIsActive = null;
          },
        );
      }
    },
    onTimeRunOut() {
      this.testRepeatIsDisabled = false;
      this.getAllTests();
    },
  },
};
</script>

<style lang="less" scoped>
.load-throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  .throbber {
    width: 50px;
    height: 50px;
    background: url("~@/assets/images/abbott/ajax-loader_abbott.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.test {
  position: relative;
  width: ~"calc(50% - 8px)";
  min-height: 90px;
  padding: 6px 45px 9px 12px;
  margin: 8px 0;
  overflow: hidden;
  font-size: 15px;
  color: var(--base-text-color);
  cursor: pointer;
  user-select: none;
  background: #fff;
  border: solid 1px var(--base-bg-color);
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    border-color: var(--base-color);
    box-shadow: 0 6px 8px 0 var(--base-shadow-color);
  }

  &--disabled {
    filter: grayscale(100%);
    opacity: 0.8;

    &:hover {
      cursor: default;
      border-color: var(--base-bg-color);
      box-shadow: none;
    }
  }

  &--shake {
    animation: shake 150ms linear 0s 2;
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    right: 17px;
    font-size: 21px;
    color: var(--base-color-lighter);
    transform: translateY(-50%);
  }

  &__timer {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1;
    color: #636363;
  }

  &-meta {
    margin-bottom: 4px;
    font-size: 11px;
    color: var(--mute-text-color);

    &__span {
      &:not(:last-child) {
        margin-right: 12px;
      }

      &.timer {
        color: var(--base-color);
      }

      &--pass {
        color: var(--positive-color);
      }
    }

    &__status-circle {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 3px;
      background: var(--mute-text-color);
      border-radius: 50%;

      &--pass {
        background: var(--positive-color);
      }
    }

    &__test-taken {
      display: inline-block;
      margin-right: 5px;
      font-size: 9px;
      color: var(--base-color-lighter);
    }
  }

  &-title {
    overflow: hidden;
    font-size: 15px;
    line-height: 1.27em;
    text-overflow: ellipsis;

    &__para {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding: 0;
      margin: 0;
      overflow: hidden;
      word-break: break-word;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}

@media (max-width: 1023px) {
  .test {
    width: 100%;
    margin-right: 0;
  }
}

@keyframes shake {
  25% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }
}
</style>
